.product-view .sticker-wrapper {
    display: none;
}

.category-products-grid {
    .old-price, .discounted-by {
        display: none;
    }
}

.special-price .price-label {
    display: none;
}

.price-box {
    .old-price {
        display: inline-block;
        margin-right: 10px;
    }

    .special-price {
        display: inline-block;
        font-weight: bold;
    }
}

.the-slideshow-wrapper-outer {
    border: solid 10px #f1f1f1;
    border-width: 0 0 10px 0;
}

.sprayer-type-section {
    text-align: center;
    background-image: linear-gradient(#ffffff, #e4e4e4);
    padding-bottom: 50px;
    border: solid 15px #f1f1f1;
    border-width: 0 0 15px 0;
}

.sprayer-type {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: bottom;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    width: 290px;
    padding-top: 200px;
    font-size: 15pt;
    font-weight: bold;
    line-height: 25px;
    cursor: pointer;
    margin-bottom: 40px;
}

@media all and (min-width: 1171px) {
    .sprayer-type {
        font-size: 12pt;
        width: 220px;
    }
}

@media all and (min-width: 1680px) {
    .sprayer-type {
        font-size: 15pt;
        width: 280px;
    }
}

.slideshow-wrapper {
    width: 100% !important;
}